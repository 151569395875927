import moment from 'moment/moment'
import Logo from '../assets/logo.png'

const Footer = () => {
  return (
    <footer className="w-full px-4 flex flex-col md:flex-row justify-center items-center bg-gray-300">
      <div className="px-10 py-4 md:min-h-[120px] flex items-center justify-center">
        <img src={Logo} alt="AIS Logo" className="w-40 md:w-36" />
      </div>
      <div className="text-center text-xs text-slate-800">
        <p>
          01773 814400 Trigg House, 11 Maisies Way, South Normanton, Derbyshire,
          DE55 2DS
        </p>
        <p>
          © {moment().format('YYYY')} Absence Insurance Services | Registered in
          England No. 03475198 | Authorised and Regulated by the Financial
          Conduct
        </p>
        <p>Authority (Reg No. 309701)</p>
        <a
          href="//schooladvice.co.uk/privacy"
          target="_blank"
          rel="noreferrer"
          className="mt-2 block text-blue-800"
        >
          Privacy Notice
        </a>
      </div>
    </footer>
  )
}

export default Footer
